export const TINYMCE_INIT_TEMPLATE = `<!DOCTYPE html>
<html lang="ru">
<head>
    <meta charset="UTF-8"><meta content="text/html; charset=utf-8" http-equiv="Content-Type">
    <style>
      p {
          margin-bottom: 0 !important;
          margin-top: 0 !important;
      }

      .signature-table p {
          margin-bottom: 0 !important;
          margin-top: 0 !important;
      }

      .signatures-0 {
        display: inline-block;
        max-height: 85px;
        margin-bottom: -20px;
      }

      .signatures-1 {
        max-height: 265px;
        display: inline-block;
        margin-left: 10px;
      }

      table {
        border: none;
      }
    </style>
</head>
<body
    style="width: 982px; overflow-x: hidden; margin-left: auto; margin-right: auto; padding: 40px 20px; word-break: break-all;">{{ content }}</body>
</html>`;

const RAW_PRINT_TEMPLATE = `<!DOCTYPE html>
<html lang="ru">
<head>
    <meta charset="UTF-8"><meta content="text/html; charset=utf-8" http-equiv="Content-Type">
    <style>
      table {
        border: none;
      }

      p {
          margin-bottom: 0 !important;
          margin-top: 0 !important;
      }

      .signature-table p {
          margin-bottom: 0 !important;
          margin-top: 0 !important;
      }

      .signatures-0 {
        display: inline-block;
        max-height: 85px;
        margin-bottom: -20px;
      }

      .signatures-1 {
        max-height: 265px;
        display: inline-block;
        margin-left: 10px;
      }
    </style>
</head>
  <body width="982" style="width:982px; max-width: 982px; overflow: hidden">
      <section class="print-container" style="width:982px; max-width: 982px; overflow: hidden">{{ content }}</section></div>
  </body>
</html>`;

export const prepareToSave = (inputString: string) => {
  const matchResult2 = inputString.match(/<body[\s\S]*?>([\s\S]+?)<\/body>/i);
  if (!matchResult2 || !matchResult2.length) {
    return RAW_PRINT_TEMPLATE.replace(/{{ content }}/i, inputString);
  }
  const rawBody = matchResult2[1];
  return RAW_PRINT_TEMPLATE.replace(/{{ content }}/i, rawBody);
};

export const prepareDocumentToEditor = (inputString: string) => {
  if (!inputString.length) return TINYMCE_INIT_TEMPLATE;
  // match new template
  const matchResult = inputString
    .match(/<section class="print-container"[\s\S]*?>([\s\S]+)<\/section>/i);
  if (matchResult && matchResult.length) {
    const content = matchResult[1]
      .replaceAll('<div', '<p')
      .replaceAll('</div', '</p');
    return TINYMCE_INIT_TEMPLATE.replace('{{ content }}', content);
  }
  // match old template
  const matchResult2 = inputString.match(/<body[\s\S]*?>([\s\S]+?)<\/body>/i);
  if (!matchResult2 || !matchResult2.length) {
    return TINYMCE_INIT_TEMPLATE
      .replace(/{{ content }}/i, inputString)
      .replaceAll('<div', '<p')
      .replaceAll('</div', '</p');
  }
  const rawBody = matchResult2[1];
  return TINYMCE_INIT_TEMPLATE
    .replace(/{{ content }}/i, rawBody)
    .replaceAll('<div', '<p')
    .replaceAll('</div', '</p');
};

export const getInitTemplate = () => TINYMCE_INIT_TEMPLATE
  .replace('{{ content }}', '');

export const SIGNATURE_TEMPLATE = `
<table style="width: 100%" class="signature-table">
  <tr>
    <td style="width: 50%; vertical-align: bottom;">
      <strong>
       Представитель {{Организация_Название}}<br/>
       по доверенности
      </strong>
    </td>
    <td style="width: 50%; vertical-align: bottom;">
      {{Организация_Подпись}}&nbsp;&nbsp;&nbsp;
      <strong>
        {{Организация_Представитель_По_Доверенности}}
      </strong>
    </td>
  </tr>
  <tr>
    <td>

    </td>
    <td>
      <p style="text-align: center">{{Организация_Печать}}</p>
    </td>
  </tr>
</table>
<p style="text-align: center;">{{ЭЦП_Печать}}</p>
<p>{{ЭЦП_Текст}}</p>`;

export const getSignatureTemplate = (variables: Record<string, string>) => Object
  .entries(variables)
  .reduce(
    (acc, [name, htmlString]) => acc.replace(`{{${name}}}`, htmlString),
    SIGNATURE_TEMPLATE,
  );
