import { useActiveTable } from '@/components/activeTable/useActiveTable';
import { ConstructorTemplate, useConstructor } from '@/hooks/useConstructor';
import { computed, ref } from 'vue';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';

export function useFetchTemplates() {
  const allTemplates = useTemplates();
  const courtOrderTemplates = useTemplates('court_order');
  const shareholderTemplates = useTemplates('court_order_shareholders');
  return {
    allTemplates,
    courtOrderTemplates,
    shareholderTemplates,
  };
}

function useTemplates(type?: 'court_order' | 'court_order_shareholders') {
  const { company } = useDefaultCompany();
  const {
    fetchConstructorTemplates,
  } = useConstructor();
  const {
    records,
  } = useActiveTable<ConstructorTemplate, ConstructorTemplate, 'id'>({
    keyField: 'id',
    async fetch({ params, signal }) {
      const { response } = await fetchConstructorTemplates({ ...params, signal });
      return response;
    },
    defaultLimit: ref(1000),
    filters: computed<Array<ActiveFormField<ConstructorTemplate>>>(() => ([
      filter('company_id', company.value?.id),
      filter('template_type_name', type),
    ])),
  });
  return records;
}

function filter(key: string, value: any) {
  return {
    key,
    field: key,
    type: ActiveFormFieldType.input,
    defaultValue: value,
  };
}
