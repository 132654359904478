import {
  useRouter, useRoute, onBeforeRouteLeave, RouteLocationNormalized,
} from 'vue-router';
import {
  computed, Ref,
} from 'vue';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { RouterKey } from '@core/symbols';

export const useQuerySearchParameter = (
  key: string,
  defaultValue: Ref<string|undefined>,
  shouldKeepParam?: (to: RouteLocationNormalized) => boolean,
) => {
  const router = useProtectedInject(RouterKey);
  const route = useRoute();

  const param = computed({
    get: () => route.query[key] || defaultValue.value,
    set: (v) => {
      router.push({
        query: {
          ...route.query,
          [key]: v ?? undefined,
        },
      });
    },
  });

  if (shouldKeepParam) {
    onBeforeRouteLeave((to, from, next) => {
      if (shouldKeepParam(to)) {
        if (to.query[key] !== param.value) {
          next({ ...to, query: { ...to.query, [key]: param.value } });
        } else {
          next();
        }
      } else {
        next();
      }
    });
  }

  return param;
};
