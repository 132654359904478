import { computed, Ref } from 'vue';
import { ProductionType, TemplateType, useConstructor } from '@/hooks/useConstructor';
import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useI18n } from 'vue-i18n';

type CreateTemplateTypeFormModel = { name: string; description: string; production_type: ProductionType|undefined};

export const useCreateTemplateTypeDialog = (
  companyId: Ref<number>,
  productionType: Ref<ProductionType|undefined>,
) => {
  const { t: globalT } = useI18n();
  const { t } = useLocalI18n('panel.constructor');

  const {
    createTemplateType,
  } = useConstructor();

  return useModelDialog<
    CreateTemplateTypeFormModel,
    TemplateType
    >({
      title: 'Добавление нового типа документа',
      getForm: () => Promise.resolve({
        status: true,
        response: {
          name: '',
          description: '',
          production_type: productionType.value,
        },
      }),
      fields: ([
        {
          key: 'name',
          field: 'name',
          type: ActiveFormFieldType.input,
          options: {
            label: t('addType.name.label'),
            hint: t('addType.name.hint'),
          },
        },
        {
          key: 'description',
          field: 'description',
          type: ActiveFormFieldType.input,
          options: {
            label: t('addType.description'),
          },
        },
        {
          key: 'production_type',
          field: 'production_type',
          type: ActiveFormFieldType.select,
          options: {
            label: t('addType.description'),
            options: ([
              ProductionType.pretrial,
              ProductionType.judicial,
              ProductionType.executive,
            ]).map((value) => ({
              value,
              label: globalT(`productionType.${value}`),
            })),
          },
        },
      ]),
      onModelUpdate: async (payload) => {
        const errorsMap: Record<string, string[]> = {};
        let anyError = false;
        Object.entries(payload).forEach(([key, value]) => {
          if (!value) {
            anyError = true;
            errorsMap[key] = ['Поле обязательно'];
          }
        });
        if (anyError) {
          return {
            status: false,
            response: errorsMap,
          };
        }

        return createTemplateType({
          production_type: payload.production_type!,
          description: payload.description,
          name: payload.name.split(' ').join('_'),
          company_owner: companyId.value,
          custom_print: true,
        });
      },
    });
};
