import { computed, ref } from 'vue';
import { ProductionType } from '@/hooks/useConstructor';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { useQuerySearchParameter } from '@/hooks/useQuerySearchParameters';

export const useTemplateFilters = () => {
  const { t } = useLocalI18n('panel.constructor.filters');
  const {
    permissionsMap,
  } = useDefaultCompany();

  const defaultFilter = computed(
    () => Object.entries(permissionsMap.value)
      .filter(([k, v]) => [
        ProductionType.pretrial, ProductionType.judicial, ProductionType.executive,
      ].includes(k as ProductionType) && v === 'write')?.[0]?.[0] as ProductionType,
  );

  const filterModel = ref({
    filter: useQuerySearchParameter(
      'filter',
      defaultFilter,
      (
        route,
      ) => [
        'panel-constructor', 'panel-constructor-template', 'panel-constructor-templates',
      ].includes(route.name as string),
    ),
  });

  const filterOptions = computed(() => [
    permissionsMap.value[ProductionType.pretrial] === 'write' && {
      label: t('filter.pretrial'),
      value: ProductionType.pretrial,
    },
    permissionsMap.value[ProductionType.judicial] === 'write' && {
      label: t('filter.judicial'),
      value: ProductionType.judicial,
    },
    permissionsMap.value[ProductionType.executive] === 'write' && {
      label: t('filter.executive'),
      value: ProductionType.executive,
    }].filter(Boolean));

  return {
    filterModel,
    filterOptions,
  };
};
